import React from 'react'
import Slider from 'react-slick'
import { BLOCKS } from '@contentful/rich-text-types'

import Button from './Button'
import RichText from './RichText'

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true
};

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => <p className="text-white">{children}</p>
  }
}

export const HeroSlider = ({ slides, location }) => (
  <Slider {...sliderSettings}>
    {slides.map((slide, index) => (
      <Hero key={index} image={slide.backgroundImage.fixed.src} location={location}>
        <HeroInfo title={slide.title} link={slide.link} linkText={slide.linkText}>
          <div className="text-white mb-6 max-w-md text-shadow">
            <RichText json={slide.content.json} options={options} />
          </div>
        </HeroInfo>
      </Hero>
    ))}
  </Slider>
)

export const HeroInfo = ({ children, title, link, linkText}) => (
  <div className="container py-16 md:py-32 px-3 w-3/4 mx-auto">
    <h1 className="text-4xl text-white mb-6 max-w-md text-shadow uppercase">{title}</h1>
    {children}
    {(link && linkText) && <Button to={link} className="btn btn-green">{linkText}</Button>}
  </div>
)

const Hero = ({ children, image, location, className }) => (
  <div style={{backgroundImage: `url(${image})`}} className={`${className} bg-cover bg-center ${(location && location.pathname === '/') && ' pt-20 sm:pt-32 md:pt-40'}`}>
    {children}
  </div>
)

export default Hero
