import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { BLOCKS } from "@contentful/rich-text-types"

import Layout from '../components/Layout'
import Hero, { HeroInfo } from '../components/global/Hero'
import SEO from '../components/global/SEO'
import { Section } from '../components/global/Section'
import RichText from '../components/global/RichText'

const query = graphql`
  query PrivateToursQuery	{
    contentfulPage(slug: {
      eq: "private-bespoke-coach-tours"
    }) {
      title
      slug
      featuredImage {
        description
        fixed(width: 1600) {
          src
        }
      }
      shortDescription {
        json
      }
      content {
        json
      }
      seoTitle
      seoDescription
      seoSocialMediaImage {
        resize(width: 1200, height: 630) {
          src
        }
      }
    }
  }
`

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => <p className="text-white">{children}</p>
  }
}

const PrivateTours = () => (
  <StaticQuery
    query={query}
    render={({ contentfulPage }) => 
      <Layout>
        <SEO title={contentfulPage.seoTitle} description={contentfulPage.seoDescription} image={contentfulPage.seoSocialMediaImage && contentfulPage.seoSocialMediaImage.resize.src} />
        <Hero image={contentfulPage.featuredImage.fixed.src}>
          <HeroInfo title={contentfulPage.title}>
            <div className="mb-6 max-w-md text-shadow">
              <RichText json={contentfulPage.shortDescription.json} options={options} />
            </div>
          </HeroInfo>
        </Hero>
        <Section>
          <div className="mx-auto lg:w-3/5">
            <RichText json={contentfulPage.content.json} />
          </div>
        </Section>
      </Layout>
    }
  />
)

export default PrivateTours